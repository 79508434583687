import React, { useState } from 'react';

const DateRangePicker = () => {
  const [checkin, setCheckin] = useState('');
  const [checkout, setCheckout] = useState('');

  return (
    <div style={styles.div}>
      <label>
        Check-in:
        <input
          type="date"
          value={checkin}
          onChange={(e) => setCheckin(e.target.value)}
          required
        />
      </label>
      <label>
        Check-out:
        <input
          type="date"
          value={checkout}
          onChange={(e) => setCheckout(e.target.value)}
          required
        />
      </label>
    </div>
  );
};

const styles = {
    // ... (other styles)
    div: {
      display: 'flex',
      //flexDirection: ',
      gap: '10px', // Adjust spacing as needed
    },
  };

export default DateRangePicker;
