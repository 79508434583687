import React, { useState } from 'react';
import './App.css';
import BookingContext from './Context';

import HomePage from './HomePage';
import ContactForm from './ContactForm';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
  const [roomData, setRoomData] = useState({
    name: 'MATRIMONIAL ESTÁNDAR',
    description: 'Habitación cómoda para dos personas con una cama matrimonial, incluye baño privado y Wi-Fi',
    capacity: 2,
    price: 50.000,
    imageUrl: 'room1.jpeg',
  });



  return (
    <BookingContext.Provider value={{roomData , setRoomData}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/form" element={<ContactForm />} />
        </Routes>
      </BrowserRouter>
    </BookingContext.Provider>
  );
}

export default App;
