import React, { useContext } from 'react';
import BookingContext from '../Context';

import RoomCard from './RoomCard'; // Importa el componente
import DateSelecction from './DateSelection'; // Importa el componente
import { useNavigate } from 'react-router-dom';

const ReservationForm = () => {
  const navigate = useNavigate();
  const {roomData, setRoomData} = useContext(BookingContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setRoomData({...roomData, name:"MATRIMONIAL ESTÁNDAR DELUXE"})
    navigate('/form');
  };

  return (
    <form style={styles.form} onSubmit={handleSubmit}>
      <DateSelecction />
      <RoomCard room={roomData} />
      <button type="submit" style={styles.button}>
        Reservar
      </button>

    </form>
  );
};

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
  },
  button: {
    backgroundColor: '#457B9D', // Azul relajante
    color: '#FFFFFF',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ReservationForm;

