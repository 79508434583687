import React from 'react';

import Header from './components/Header/Header';
import ReservationForm from './components/ReservationForm';

const HomePage = () => {
    return (
        <div className="App">
            <Header />
            <ReservationForm />
        </div>
    );
}

export default HomePage;